const select2 = {
  mounted(el) {
    $(el).on("select2:select", () => {
      const event = new Event("change", {
        bubbles: true,
        cancelable: true,
      });
      el.dispatchEvent(event);
    });

    $(el).on("select2:unselect", () => {
      const event = new Event("change", {
        bubbles: true,
        cancelable: true,
      });
      el.dispatchEvent(event);
    });
  },
};

export default select2;
