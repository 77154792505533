import select2 from "./directives/select2.js";
import tooltip from "./directives/tooltip.js";
import "@/assets/tooltip.css";

export default {
  install(app) {
    app.directive("select2", select2);
    app.directive("tooltip", tooltip);
  },
};
