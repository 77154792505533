import AuthService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));
const mode = localStorage.getItem("darkMode") == "true";
const initialState = user
  ? { status: { loggedIn: true, dark: mode }, user }
  : { status: { loggedIn: false, dark: mode }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user)
        .then((user) => {
          if (!user.permissions.includes("claims-process")) {
            AuthService.logout();
            commit("logout");
            window.location.assign("https://portal.varsanpr.com");
          }
          commit("loginSuccess", user);
          return Promise.resolve(user);
        })
        .catch((error) => {
          commit("loginFailure");
          return Promise.reject(error);
        });
    },
    checkToken({ commit }, token) {
      console.log("Token: ", token);
      return AuthService.checkToken(token)
        .then((user) => {
          user.token = token;
          commit("loginSuccess", user);
          return Promise.resolve(user);
        })
        .catch((error) => {
          commit("loginFailure");
          return Promise.reject(error);
        });
    },
    logout({ commit }) {
      return AuthService.logout().then(() => {
        commit("logout");
      });
    },
    selectClient({ commit }, client) {
      commit("selectClient", client);
    },
    changeMode({ commit }, mode) {
      commit("changeMode", mode);
    },
  },
  mutations: {
    loginSuccess(state, user) {
      if (
        [
          "a.gore@varstechnology.co.uk",
          "j.higgins@varstechnology.co.uk",
        ].includes(user.user.email)
      ) {
        window.location.assign("https://admin.varsanpr.com");
      }
      state.status.loggedIn = true;
      state.user = {
        permissions: user.permissions,
        ...user.user,
        clients: user.clients,
        token: user.token,
        ws_url: user.ws_url,
      };
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    selectClient(state, client) {
      state.user.selectedClient = client.id;
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    changeMode(state, mode) {
      state.status.dark = mode;
      localStorage.setItem("darkMode", mode);
    },
  },
};
