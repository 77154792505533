<template>
  <div class="app app-auth-sign-in align-content-stretch d-flex flex-wrap justify-content-end">
        <div class="app-auth-background auth-background-light">
        </div>
        <div class="app-auth-container">
            
        </div>
    </div>
</template>

<script>

export default {
  name: 'Login',
  data(){
    return {
      loading: false,
      message: ""
    }
  },
  mounted() {
    if(this.$route.query.token){

      this.loading = true;
      this.$store.dispatch('auth/checkToken', this.$route.query.token).then(() => {
        this.loading = false;
        this.$router.push('/claims/management');
      })
      .catch(() => {
        this.loading = false;
        this.$router.push('/login');
      })

    }else{
      window.location.href = 'https://gateway.varsanpr.com/login?redirect=' + window.location.origin + '/login';
    }
  },
  computed: {
    loggedIn(){
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created(){
    if(this.loggedIn){
      this.$router.push('/claims/management');
    }
  }
}
</script>

<style scoped>
.app-auth-background {
  height: 100vh;
}

.auth-background-light {
  background: url('../../public/img/vars_logo_black.png') no-repeat;
  background-size: 75%;
  background-position: center;
}
.auth-background-dark {
  background: url('../../public/img/vars_logo_white.png') no-repeat;
  background-size: 75%;
  background-position: center;
}
</style>
